import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  TextField,
  Grid,
  Button,
  Avatar,
  Divider,
  Snackbar,
  Alert
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { useAuth0 } from '@auth0/auth0-react';
import { styled } from '@mui/material/styles';
import { formatDateForInput, formatDateToDDMMYYYY } from '../../utils/date_utils';
import { getUser, saveOrUpdateUser } from '../../utils/server_utils';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: 16,
}));

const DatosPersonales = ({setNotifyInformationCompleted}) => {
  const { user, isAuthenticated, getAccessTokenSilently, isLoading: authLoading } = useAuth0();

  const [userData, setUserData] = useState({
    nombre: '',
    apellido: '',
    email: user?.email || '',
    telefono: '',
    fechaNacimiento: '',
    direccion: ''
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});

  // Age validation function
  const validateAge = (birthDate) => {
    if (!birthDate) return false;
    
    const today = new Date();
    const birthDateObj = new Date(birthDate);
    
    let age = today.getFullYear() - birthDateObj.getFullYear();
    const monthDiff = today.getMonth() - birthDateObj.getMonth();
    
    // Adjust age if birthday hasn't occurred yet this year
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDateObj.getDate())) {
      age--;
    }
    
    if (age < 18) {
      setValidationErrors(prev => ({
        ...prev,
        fechaNacimiento: 'Debes ser mayor de 18 años para registrarte'
      }));
      return false;
    } else {
      setValidationErrors(prev => {
        const newErrors = {...prev};
        delete newErrors.fechaNacimiento;
        return newErrors;
      });
      return true;
    }
  };

  const fetchUserData = async () => {
    // Existing fetch logic...
    try {
      setLoading(true);
      
      // Get token for API authorization
      const token = await getAccessTokenSilently();
      
      // Make API request with the user's email
      const response = await getUser(user.email, token);

      if (response.data.first_login) {
        return;
      }
        
      const userData = {
         nombre: response.data.nombres.value,
         apellido: response.data.apellidos.value,
         email: response.data.email.value,
         telefono: response.data.celular.value,
         fechaNacimiento: formatDateForInput(response.data.fecha_nacimiento.value),
         direccion: response.data.direccion.value
      };

      setNotifyInformationCompleted({userName: userData.nombre, email: userData.email, isVisible: true});
      setUserData(userData);

    } catch (err) {
      console.error('Error fetching user data:', err);
      setError('No pudimos cargar tus datos. Por favor, intenta nuevamente.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Skip if Auth0 is still loading or user is not authenticated
    if (authLoading || !isAuthenticated || !user?.email) 
      return;
    
    fetchUserData();
  }, [user, isAuthenticated, getAccessTokenSilently, authLoading]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData(prev => ({
      ...prev,
      [name]: value
    }));
    
    // Validate age when birth date changes
    if (name === 'fechaNacimiento') {
      validateAge(value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validate age before submitting
    const isAgeValid = validateAge(userData.fechaNacimiento);
    
    // Don't submit if age validation fails
    if (!isAgeValid) {
      return;
    }

    const response = await saveOrUpdateUser({
      nombres: userData.nombre,
      apellidos: userData.apellido,
      email: userData.email,
      celular: userData.telefono,
      fecha_nacimiento: formatDateToDDMMYYYY(userData.fechaNacimiento),
      direccion: userData.direccion
    });

    setOpenSnackbar(true);
    fetchUserData();
  };

  return (
    <Box>
      <Typography variant="h4" sx={{ mb: 4 }}>
        Datos Personales
      </Typography>      
      <StyledPaper elevation={3}>
        <form onSubmit={handleSubmit}>
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, mb: 4 }}>
        <Box 
          sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center', 
            mr: { md: 6 },
            mb: { xs: 4, md: 0 }
          }}
        >
            <Avatar
              src="https://images.unsplash.com/photo-1551946581-f7a62cd2117d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=200&h=200&q=80"
              sx={{ width: 120, height: 120, mb: 2 }}
            />
            <Button variant="outlined" size="small">
            Cambiar foto
          </Button>
        </Box>
        
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Información Básica
          </Typography>
          
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                required
                label="Nombre"
                name="nombre"
                value={userData.nombre}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Apellido"
                name="apellido"
                value={userData.apellido}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                disabled
                fullWidth
                label="Correo Electrónico"
                name="email"
                type="email"
                value={userData.email}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Teléfono"
                name="telefono"
                value={userData.telefono}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      
      <Divider sx={{ my: 4 }} />
      
      <Typography variant="h6" sx={{ mb: 2 }}>
        Información Adicional
      </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                required
                label="Fecha de Nacimiento"
                name="fechaNacimiento"
                type="date"
                value={userData.fechaNacimiento}
                onChange={handleChange}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                error={!!validationErrors.fechaNacimiento}
                helperText={validationErrors.fechaNacimiento}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Dirección"
                name="direccion"
                value={userData.direccion}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
          </Grid>
          
          <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end' }}>
            <Button 
              type="submit" 
              variant="contained" 
              color="primary" 
              startIcon={<SaveIcon />}
              disabled={Object.keys(validationErrors).length > 0}
            >
              Guardar Cambios
            </Button>
          </Box>
        </form>
      </StyledPaper>
      
      <Snackbar 
        open={openSnackbar} 
        autoHideDuration={6000} 
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => setOpenSnackbar(false)} 
          severity="success"
          sx={{ width: '100%' }}
        >
          ¡Datos guardados con éxito!
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default DatosPersonales;