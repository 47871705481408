import React from 'react';
import {
  Box,
  Container,
  Typography,
  Grid,
  Link,
  IconButton,
  Divider
} from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

const Footer = () => {
  return (
    <Box component="footer" sx={{ bgcolor: '#e5f2f4', pt: 6, pb: 4 }}>
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" component="h3" sx={{ mb: 2 }}>
              White-Mirror
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
              Plataforma de terapia personalizada con inteligencia artificial.
              Mejora tu bienestar emocional desde cualquier lugar.
            </Typography>
          </Grid>
          
          <Grid item xs={12} md={4}>
            <Typography variant="h6" component="h3" sx={{ mb: 2 }}>
              Enlaces Rápidos
            </Typography>
            <Link href="#" underline="none" color="text.primary" display="block" sx={{ mb: 1 }}>
              Sobre Nosotros
            </Link>
            <Link href="#" underline="none" color="text.primary" display="block" sx={{ mb: 1 }}>
              Servicios
            </Link>
            <Link href="#" underline="none" color="text.primary" display="block" sx={{ mb: 1 }}>
              Preguntas Frecuentes
            </Link>
            <Link href="#" underline="none" color="text.primary" display="block" sx={{ mb: 1 }}>
              Contacto
            </Link>
          </Grid>
          
          <Grid item xs={12} md={4}>
            <Typography variant="h6" component="h3" sx={{ mb: 2 }}>
              Síguenos en RRSS
            </Typography>
            <Box>
              <IconButton 
                aria-label="Facebook" 
                component="a"
                href="https://facebook.com/"
                target="_blank"
                rel="noopener"
                sx={{ mr: 1, color: '#4267B2' }}
              >
                <FacebookIcon fontSize="large" />
              </IconButton>
              <IconButton 
                aria-label="Instagram" 
                component="a"
                href="https://instagram.com/"
                target="_blank"
                rel="noopener"
                sx={{ color: '#C13584' }}
              >
                <InstagramIcon fontSize="large" />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
        
        <Divider sx={{ my: 4, borderColor: 'rgba(0, 0, 0, 0.1)' }} />
        
        <Typography variant="body2" align="center" color="text.secondary">
            © {new Date().getFullYear()} White-Mirror. Todos los derechos reservados.
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;