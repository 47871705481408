import React, { useEffect, useState } from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Avatar,
  Typography,
  Divider,
  IconButton,
  useMediaQuery,
  Paper
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Link, useLocation } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import StarIcon from '@mui/icons-material/Star';
import FeedbackIcon from '@mui/icons-material/Feedback';
import ChatIcon from '@mui/icons-material/Chat';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import CloseIcon from '@mui/icons-material/Close';
import { useAuth0 } from '@auth0/auth0-react';

const Sidebar = ({ open, onClose, notifyInformationCompleted }) => {
  const theme = useTheme();
  const location = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isVisible = notifyInformationCompleted.notifyInformationCompleted.isVisible;
  const userName = notifyInformationCompleted.notifyInformationCompleted.userName;
  const { user } = useAuth0();
  
  const drawerWidth = 280;

  useEffect(() => {
    if (!user)
      return;
  }, [user]);
  

  
  
  const menuItems = [
    {
      text: 'Datos Personales',
      icon: <PersonIcon />,
      path: '/dashboard/datos-personales',
      isVisible: true
    },
    {
      text: 'Reservar Hora',
      icon: <EventAvailableIcon />,
      path: '/dashboard/reservar-hora',
      isVisible: isVisible
    },
    {
      text: 'Obtener Premium',
      icon: <StarIcon />,
      path: '/dashboard/obtener-premium',
      isVisible: isVisible
    },
    {
      text: 'Ayúdanos a Mejorar',
      icon: <FeedbackIcon />,
      path: '/dashboard/ayudanos-mejorar',
      isVisible: isVisible
    },
    {
      text: 'Chatbot',
      icon: <ChatIcon />,
      path: '/dashboard/chatbot',
      isVisible: isVisible
    }
  ];

  const content = (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      {isMobile && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 1 }}>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      )}
      
      <Box sx={{ 
        p: 1, 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        mt: isMobile ? 4 : 8 
      }}>
        <Paper elevation={2} sx={{ borderRadius: '50%' }}>
          <Avatar
            src={user?.picture}
            sx={{ 
              width: 80, 
              height: 80,
            }}
          />
        </Paper>
        <Typography variant="h6" sx={{ mt: 2, color: 'text.primary', fontWeight: 600 }}>
          {userName}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {user?.email}
        </Typography>
      </Box>
      
      <Divider sx={{ mx: 2, my: 2 }} />
      
      <List sx={{ flexGrow: 1, px: 2 }}>
        {menuItems.filter(e => e.isVisible).map((item) => (
          <ListItem 
            button 
            key={item.text} 
            component={Link} 
            to={item.path}
            selected={location.pathname === item.path}
            sx={{
              mb: 1.5,
              borderRadius: 2,
              '&.Mui-selected': {
                backgroundColor: 'rgba(140, 225, 222, 0.25)',
                '&:hover': {
                  backgroundColor: 'rgba(140, 225, 222, 0.35)',
                },
              },
              '&:hover': {
                backgroundColor: 'rgba(140, 225, 222, 0.15)',
              }
            }}
          >
            <ListItemIcon sx={{ color: location.pathname === item.path ? 'primary.dark' : 'text.secondary' }}>
              {item.icon}
            </ListItemIcon>
            <ListItemText 
              primary={item.text} 
              primaryTypographyProps={{
                fontWeight: location.pathname === item.path ? 600 : 400
              }}
            />
          </ListItem>
        ))}
      </List>
      
      <Box sx={{ p: 2, textAlign: 'center' }}>
        <Typography variant="caption" color="text.secondary" display="block">
          White-Mirror v1.0
        </Typography>
      </Box>
    </Box>
  );

  return (
    <>
      {isMobile ? (
        <Drawer
          variant="temporary"
          open={open}
          onClose={onClose}
          ModalProps={{ keepMounted: true }}
          sx={{
            '& .MuiDrawer-paper': { 
              width: drawerWidth,
              bgcolor: 'background.paper'
            },
          }}
        >
          {content}
        </Drawer>
      ) : (
        <Drawer
          variant="permanent"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': { 
              width: drawerWidth,
              bgcolor: 'background.default',
              borderRight: 'none',
              boxShadow: '0 0 20px rgba(44, 62, 80, 0.05)',
              mt: '64px', 
              height: 'calc(100% - 64px)'
            },
          }}
          open
        >
          {content}
        </Drawer>
      )}
    </>
  );
};

export default Sidebar;