import React from 'react';
import { 
  Box, 
  Container, 
  Typography, 
  Button,
  Grid,
  Card,
  CardMedia
} from '@mui/material';
import { styled } from '@mui/material/styles';

const HeroWrapper = styled(Box)(({ theme }) => ({
    background: theme.palette.background.default,
    padding: theme.spacing(10, 0),
    borderRadius: '0 0 30px 30px',
    position: 'relative',
    overflow: 'hidden',
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  zIndex: 2,
}));

const Hero = () => {
  return (
    <HeroWrapper>
      <Container maxWidth="lg">
        <ContentWrapper>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
            <Typography 
                variant="h1" 
                component="h1" 
                sx={{ mb: 3, color: 'text.primary', fontWeight: 700 }}
              >
                Encuentra tu terapia personalizada con IA
              </Typography>
              <Typography 
                variant="body1" 
                sx={{ mb: 4, fontSize: '1.1rem', color: 'text.primary' }}
              >
                Nuestra plataforma utiliza inteligencia artificial avanzada para 
                ofrecerte un tratamiento adaptado a tus necesidades específicas. 
                Mejora tu bienestar emocional con apoyo personalizado en tu idioma.
              </Typography>
              <Button 
                variant="contained" 
                size="large" 
                color="secondary"
                sx={{ px: 4, py: 1.5, fontWeight: 'bold' }}
              >
                Comenzar ahora
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card elevation={4} sx={{ borderRadius: 4, overflow: 'hidden' }}>
              <CardMedia
                  component="img"
                  height="350"
                  image="https://images.unsplash.com/photo-1575052814086-f385e2e2ad1b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=600&h=400&q=80"
                  alt="Terapia con IA"
                />
              </Card>
            </Grid>
          </Grid>
        </ContentWrapper>
      </Container>
    </HeroWrapper>
  );
};

export default Hero;