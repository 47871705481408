import React from 'react';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import StarIcon from '@mui/icons-material/Star';

const ObtenerPremium = () => {
  const plans = [
    {
      title: 'Plan Básico',
      price: 'Gratis',
      features: [
        'Acceso a evaluaciones básicas',
        'Chatbot con respuestas limitadas',
        '1 sesión de terapia al mes',
        'Recursos educativos básicos'
      ],
      current: true,
      color: '#8CE1DE'
    },
    {
      title: 'Plan Premium',
      price: '30.000 / mes',
      features: [
        'Acceso ilimitado a evaluaciones',
        'Chatbot con IA avanzada',
        '4 sesiones de terapia al mes',
        'Recursos personalizados',
        'Seguimiento de progreso',
        'Soporte prioritario 24/7'
      ],
      current: false,
      color: '#5CBCB9'
    }
  ];

  return (
    <Box>
      <Typography variant="h4" sx={{ mb: 2 }}>
        Obtener Premium
      </Typography>
      
      <Typography variant="body1" sx={{ mb: 4 }}>
        Mejora tu experiencia con acceso a funciones avanzadas y sesiones adicionales.
      </Typography>
      
      <Paper 
        elevation={0} 
        sx={{ 
          bgcolor: 'primary.light', 
          p: 2, 
          borderRadius: 2, 
          mb: 4,
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <StarIcon sx={{ mr: 2, color: 'secondary.main' }} />
        <Typography variant="body2">
          Actualmente estás en el <strong>Plan Básico</strong>. Actualiza ahora y obtén un <strong>20% de descuento</strong> en tu primer mes.
        </Typography>
      </Paper>
      
      <Grid container spacing={3} display={"flex"} justifyContent={"center"} >
        {plans.map((plan) => (
          <Grid item xs={12} md={4} key={plan.title}>
            <Card 
              elevation={3} 
              sx={{ 
                height: '100%', 
                display: 'flex', 
                flexDirection: 'column',
                borderTop: `4px solid ${plan.color}`,
                borderRadius: 2,
                position: 'relative',
                ...(plan.title === 'Plan Premium' && {
                  boxShadow: '0 8px 24px rgba(0, 0, 0, 0.12)'
                })
              }}
            >
              {plan.title === 'Plan Premium' && (
                <Box 
                  sx={{ 
                    position: 'absolute', 
                    top: 10, 
                    right: 0,
                    bgcolor: 'secondary.main',
                    color: 'white',
                    py: 0.5,
                    px: 2,
                    borderRadius: '4px 0 0 4px',
                    fontSize: '0.75rem',
                    fontWeight: 'bold'
                  }}
                >
                  RECOMENDADO
                </Box>
              )}
              
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography variant="h5" component="h2" sx={{ mb: 2 }}>
                  {plan.title}
                </Typography>
                <Typography variant="h4" component="div" sx={{ mb: 3, fontWeight: 'bold' }}>
                  {plan.price}
                </Typography>
                
                <List dense>
                  {plan.features.map((feature, index) => (
                    <ListItem key={index} disableGutters>
                      <ListItemIcon sx={{ minWidth: 32 }}>
                        <CheckCircleIcon sx={{ color: plan.color }} />
                      </ListItemIcon>
                      <ListItemText primary={feature} />
                    </ListItem>
                  ))}
                </List>
              </CardContent>
              
              <CardActions sx={{ p: 2, pt: 0 }}>
                {plan.current ? (
                  <Button 
                    variant="outlined" 
                    fullWidth 
                    disabled
                  >
                    Plan Actual
                  </Button>
                ) : (
                  <Button 
                    variant="contained" 
                    fullWidth 
                    sx={{ 
                      bgcolor: plan.color,
                      '&:hover': {
                        bgcolor: plan.color,
                        filter: 'brightness(0.9)'
                      }
                    }}
                  >
                    Seleccionar Plan
                  </Button>
                )}
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ObtenerPremium;