import React, { useState } from 'react';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { BrowserRouter as Router, Routes, Route, useLocation , Navigate} from 'react-router-dom';
import theme from './theme/theme';
import ProfessionalsList from './components/dashboard/ProfesionalList';

import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
// Landing Page
import LandingPage from './components/landing/LandingPage';

// Dashboard
import Dashboard from './components/dashboard/Dashboard';
import DatosPersonales from './components/dashboard/DatosPersonales';
import ObtenerPremium from './components/dashboard/ObtenerPremium';
import AyudanosMejorar from './components/dashboard/AyudanosMejorar';
import Chatbot from './components/dashboard/Chatbot';
import MatchingIA from './components/dashboard/MatchingIA.js';


// Auth0 configuration
const Auth0ProviderWithRedirectCallback = ({ children }) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const redirectUri = window.location.origin;

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: redirectUri
      }}
    >
      {children}
    </Auth0Provider>
  );
};

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const location = useLocation();

  React.useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect({
        appState: { returnTo: location.pathname }
      });
    }
  }, [isAuthenticated, isLoading, loginWithRedirect, location]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return isAuthenticated ? children : null;
};

// Redirect if logged in component
const RedirectIfLoggedIn = ({ children }) => {
  const { isAuthenticated, isLoading } = useAuth0();
  
  if (isLoading) {
    return <div>Loading...</div>;
  }
  
  return isAuthenticated ? <Navigate to="/dashboard" /> : children;
};

function App() {

  const [notifyInformationCompleted, setNotifyInformationCompleted] = useState({});

  return (
    <Auth0ProviderWithRedirectCallback>
      <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes>
          
          {/* Landing Page - Redirect to dashboard if logged in */}
          <Route path="/" element={
              <RedirectIfLoggedIn>
                <LandingPage />
              </RedirectIfLoggedIn>
            } />

         {/* Dashboard Routes - Protected */}
         <Route path="/dashboard" element={
              <ProtectedRoute>
                <Dashboard notifyInformationCompleted={notifyInformationCompleted}/>
              </ProtectedRoute>
            }>
              <Route index element={<DatosPersonales setNotifyInformationCompleted={setNotifyInformationCompleted} />} />
              <Route path="datos-personales" element={<DatosPersonales setNotifyInformationCompleted={setNotifyInformationCompleted}/>} />
              <Route path="obtener-premium" element={<ObtenerPremium />} />
              <Route path="ayudanos-mejorar" element={<AyudanosMejorar />} />
              <Route path="chatbot" element={<Chatbot />} />
              <Route path="/dashboard/reservar-hora" element={<ProfessionalsList />} />
              <Route path="/dashboard/matching-ia" element={<MatchingIA />} />
            </Route>
        </Routes>
      </Router>
      </ThemeProvider>
    </Auth0ProviderWithRedirectCallback>
  );
}

export default App;