import React from 'react';
import Header from './Header';
import Hero from './Hero';
import ContactForm from './ContactForm';
import Footer from './Footer';

const LandingPage = () => {
  return (
    <>
      <Header />
      <Hero />
      <ContactForm />
      <Footer />
    </>
  );
};

export default LandingPage;