import React, { useState, useEffect, useRef } from 'react';
import {
  Paper, Box, List, ListItem, TextField, Button,
  Avatar, Typography, Divider, IconButton, ListItemAvatar,Tooltip,
  CircularProgress
} from '@mui/material';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import MicIcon from '@mui/icons-material/Mic';
import StopIcon from '@mui/icons-material/Stop';
import SendIcon from '@mui/icons-material/Send';
import TextsmsIcon from '@mui/icons-material/Textsms';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import DOMPurify from 'dompurify';
import CustomAudioPlayer from './CustomAudioPlayer';
import { loadUserConversation, sendFormDataForQueryV2 } from '../../utils/server_utils';
import { formatDateToDDMMYYYY } from '../../utils/date_utils';
import { useAuth0 } from '@auth0/auth0-react';

// Typing indicator animation component
const TypingIndicator = () => (
  <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
    {[0, 1, 2].map((dot) => (
      <Box
        key={dot}
        component="span"
        sx={{
          width: 8,
          height: 8,
          backgroundColor: 'grey.500',
          borderRadius: '50%',
          display: 'inline-block',
          animation: 'typing-dot 1.4s infinite ease-in-out both',
          animationDelay: `${dot * 0.2}s`,
          '@keyframes typing-dot': {
            '0%, 80%, 100%': { transform: 'scale(0.7)' },
            '40%': { transform: 'scale(1.0)' }
          }
        }}
      />
    ))}
  </Box>
);

const Chatbot = () => {
  const { user } = useAuth0();
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isRecording, setIsRecording] = useState(false);
  const [audioMode, setAudioMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  
  const messagesEndRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  
  useEffect(() => {
    async function fetchMessages() {
      if (user) {
        setIsLoading(true);
        const messages = await loadUserConversation(user.email);
        setMessages(messages);
        setIsLoading(false);
      }
    }
    
    fetchMessages();
  }, [user]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);
  
  // Add loading message
  const addLoadingMessage = () => {
    const loadingMessage = {
      id: 'loading-indicator',
      text: '',
      sender: 'bot',
      timestamp: new Date(),
      isLoading: true
    };
    
    setMessages(prev => [...prev, loadingMessage]);
  };
  
  // Remove loading message
  const removeLoadingMessage = () => {
    setMessages(prev => prev.filter(msg => msg.id !== 'loading-indicator'));
  };
  
  // Handle text message submission
  const handleSendMessage = async () => {
    if (!inputMessage.trim()) return;
    
    const newMessage = {
      id: Date.now(),
      text: inputMessage,
      sender: 'user',
      timestamp: new Date()
    };
    
    setMessages(prev => [...prev, newMessage]);
    setInputMessage('');
    
    // Add loading message bubble
    addLoadingMessage();
    
    try {
      // API call using axios with our mock service
      const formData = new FormData();
      formData.append("email", user.email);
      formData.append("user_text", inputMessage.trim());
  
      const result = await sendFormDataForQueryV2(formData);
      
      // Remove loading message
      removeLoadingMessage();
      
      const botResponse = {
        id: Date.now(),
        text: result.assistant_message_text,
        sender: 'bot',
        audioUrl : result.assistant_audio_url,
        isAudio : result.isAudio,
        isServerAudio : result.isServerAudio,
        timestamp: new Date()
      };
      
      setMessages(prev => [...prev, botResponse]);
    } catch (error) {
      console.error('Error sending message:', error);
      
      // Remove loading message
      removeLoadingMessage();
      
      const errorMessage = {
        id: Date.now(),
        text: "Lo siento, ha ocurrido un error al procesar tu mensaje. Por favor, intenta de nuevo.",
        sender: 'bot',
        timestamp: new Date()
      };
      
      setMessages(prev => [...prev, errorMessage]);
    }
  };
  
  // Start audio recording
  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);
      audioChunksRef.current = [];
      
      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };
      
      mediaRecorderRef.current.onstop = async () => {
        const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
        await sendAudioMessage(audioBlob);
        
        // Stop all audio tracks
        stream.getTracks().forEach(track => track.stop());
      };
      
      mediaRecorderRef.current.start();
      setIsRecording(true);
    } catch (error) {
      console.error('Error starting recording:', error);
      
      const errorMessage = {
        id: Date.now(),
        text: "No se pudo acceder al micrófono. Por favor, verifica los permisos.",
        sender: 'bot',
        timestamp: new Date()
      };
      
      setMessages(prev => [...prev, errorMessage]);
    }
  };
  
  // Stop audio recording
  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };
  
  // Send recorded audio to backend
  const sendAudioMessage = async (audioBlob) => {
    const userMessage = {
      id: Date.now(),
      text: "🎤 Mensaje de voz enviado",
      sender: 'user',
      timestamp: new Date(),
      isAudio: true,
      audioUrl: URL.createObjectURL(audioBlob)
    };
    
    setMessages(prev => [...prev, userMessage]);
    
    // Add loading message bubble
    addLoadingMessage();
    
    try {
      // API call using axios with our mock service

      const formData = new FormData();
      formData.append("email", user?.email);
      formData.append("file", audioBlob);
  
      const result = await sendFormDataForQueryV2(formData);
      
      removeLoadingMessage();
      

      const botResponse = {
        id: Date.now() + 1,
        text: result.assistant_message_text,
        sender: 'bot',
        timestamp: new Date(),
        audioUrl : result.assistant_audio_url,
        isAudio : true,
        isServerAudio : true
      };
      
      setMessages(prev => [...prev, botResponse]);
    } catch (error) {
      console.error('Error processing audio:', error);
      
      // Remove loading message
      removeLoadingMessage();
      
      const errorMessage = {
        id: Date.now(),
        text: "Lo siento, ha ocurrido un error al procesar tu mensaje de voz. Por favor, intenta de nuevo.",
        sender: 'bot',
        timestamp: new Date()
      };
      
      setMessages(prev => [...prev, errorMessage]);
    }
  };
  
  // Toggle between text and audio input modes
  const toggleInputMode = () => {
    setAudioMode(!audioMode);
    // If switching to text mode while recording, stop recording
    if (isRecording) {
      stopRecording();
    }
  };
  
  return (
    <Paper 
      elevation={2} 
      sx={{ 
        height: 'calc(100vh - 240px)',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 3,
        overflow: 'hidden',
        bgcolor: 'background.paper'
      }}
    >
      <Box 
        sx={{ 
          p: 2, 
          bgcolor: 'background.default',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <Avatar sx={{ bgcolor: 'white', color: 'primary.dark', mr: 2 }}>
          <SmartToyIcon />
        </Avatar>
        <Box>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: 'text.primary' }}>
            Asistente Terapéutico
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Disponible 24/7
          </Typography>
        </Box>
      </Box>
      
      <Divider />
      
      <List sx={{ 
        p: 2, 
        overflowY: 'auto', 
        flexGrow: 1,
        bgcolor: 'background.default'
      }}>
        <CircularProgress sx={{ display : isLoading ? 'block' : 'none' }} />
        {messages.map((message) => (
          <ListItem
            key={message.id}
            alignItems="flex-start"
            sx={{
              flexDirection: message.sender === 'user' ? 'row-reverse' : 'row',
              alignItems: 'flex-end',
              mb: 2,
            }}
          >
            <ListItemAvatar sx={{ minWidth: 40, mr: message.sender === 'user' ? 0 : 2, ml: message.sender === 'user' ? 2 : 0 }}>
              <Avatar 
                sx={{ 
                  bgcolor: message.sender === 'user' ? 'secondary.main' : 'primary.main',
                  width: 36, 
                  height: 36 
                }}
              >
                {message.sender === 'user' ? 
                  <Typography variant="body2">Yo</Typography> : 
                  <SmartToyIcon />
                }
              </Avatar>
            </ListItemAvatar>
            
            <Box sx={{ 
              bgcolor: message.sender === 'user' ? 'primary.light' : '#f0f2f5',
              color: message.sender === 'user' ? 'text.primary' : 'text.primary',
              p: 2,
              borderRadius: 2,
              maxWidth: '70%',
              position: 'relative',
              ...(message.sender === 'user' ? {
                borderBottomRightRadius: 0,
              } : {
                borderBottomLeftRadius: 0,
              }),
              ...(message.isTranscription && {
                bgcolor: 'background.default',
                border: '1px dashed',
                borderColor: 'primary.main',
                fontStyle: 'italic',
              })
            }}>
              {message.isLoading ? (
                <TypingIndicator />
              ) : (
                <Box dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message.text) }} />
              )}
              
              {message.isAudio && message.audioUrl && (
                <Box sx={{ mt: 1 }}>
                  {
                    <CustomAudioPlayer audioUrl={ message.isServerAudio ?  process.env.REACT_APP_API_URL+message.audioUrl : message.audioUrl  }/>
                  }
                </Box>
              )}
              
              {!message.isLoading && (
                <Typography 
                  variant="caption" 
                  sx={{ 
                    display: 'block', 
                    mt: 0.5, 
                    textAlign: message.sender === 'user' ? 'right' : 'left',
                    color: 'text.secondary'
                  }}
                >
                  {formatDateToDDMMYYYY(message.timestamp)}
                </Typography>
              )}
            </Box>
          </ListItem>
        ))}
        
        <div ref={messagesEndRef} />
      </List>
      
      <Divider />
      
      <Box sx={{ p: 2, display: 'flex', alignItems: 'center', bgcolor: 'background.paper' }}>
        <Tooltip title={audioMode ? "Cambiar a texto" : "Cambiar a voz"}>
          <IconButton 
            onClick={toggleInputMode} 
            color="primary"
            sx={{ mr: 1 }}
          >
            {audioMode ? <TextsmsIcon /> : <KeyboardVoiceIcon />}
          </IconButton>
        </Tooltip>

        {audioMode ? (
          <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
            <Button
              variant={isRecording ? "contained" : "outlined"}
              color={isRecording ? "error" : "primary"}
              startIcon={isRecording ? <StopIcon /> : <MicIcon />}
              onClick={isRecording ? stopRecording : startRecording}
              sx={{ flexGrow: 1, height: 40 }}
              disabled={messages.some(msg => msg.isLoading)}
            >
              {isRecording ? "Detener grabación" : "Pulsa para hablar"}
            </Button>
          </Box>
        ) : (
          <>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Escribe tu mensaje..."
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && !messages.some(msg => msg.isLoading) && handleSendMessage()}
              size="small"
              sx={{ mr: 1 }}
              disabled={messages.some(msg => msg.isLoading)}
            />
            <Button 
              variant="contained" 
              color="primary" 
              onClick={handleSendMessage}
              disabled={!inputMessage.trim() || messages.some(msg => msg.isLoading)}
              endIcon={<SendIcon />}
            >
              Enviar
            </Button>
          </>
        )}
      </Box>
    </Paper>
  );
};

export default Chatbot;