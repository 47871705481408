import React, { useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  TextField,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Slider,
  Grid,
  Snackbar,
  Alert
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';

const AyudanosMejorar = () => {
  const [feedback, setFeedback] = useState({
    satisfaction: 4,
    usability: 4,
    recommendation: 4,
    improvements: '',
    likedFeatures: ''
  });
  
  const [openSnackbar, setOpenSnackbar] = useState(false);
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFeedback(prev => ({
      ...prev,
      [name]: value
    }));
  };
  
  const handleSliderChange = (name) => (event, newValue) => {
    setFeedback(prev => ({
      ...prev,
      [name]: newValue
    }));
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Feedback submitted:', feedback);
    setOpenSnackbar(true);
    
    // Reset form partially
    setFeedback(prev => ({
      ...prev,
      improvements: '',
      likedFeatures: ''
    }));
  };
  
  return (
    <Box>
      <Typography variant="h4" sx={{ mb: 2 }}>
        Ayúdanos a Mejorar
      </Typography>
      
      <Typography variant="body1" sx={{ mb: 4 }}>
        Tu opinión es muy valiosa para nosotros. Por favor, tómate un momento para completar esta encuesta.
      </Typography>
      
      <Paper elevation={3} sx={{ p: 4, borderRadius: 2 }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography id="satisfaction-slider" gutterBottom>
                ¿Qué tan satisfecho/a estás con nuestra plataforma?
              </Typography>
              <Box sx={{ px: 2 }}>
                <Slider
                  value={feedback.satisfaction}
                  onChange={handleSliderChange('satisfaction')}
                  aria-labelledby="satisfaction-slider"
                  valueLabelDisplay="auto"
                  step={1}
                  marks
                  min={1}
                  max={5}
                />
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography variant="body2" color="text.secondary">Nada satisfecho/a</Typography>
                  <Typography variant="body2" color="text.secondary">Muy satisfecho/a</Typography>
                </Box>
              </Box>
            </Grid>
            
            <Grid item xs={12}>
              <Typography id="usability-slider" gutterBottom>
                ¿Qué tan fácil te resulta usar nuestra plataforma?
              </Typography>
              <Box sx={{ px: 2 }}>
                <Slider
                  value={feedback.usability}
                  onChange={handleSliderChange('usability')}
                  aria-labelledby="usability-slider"
                  valueLabelDisplay="auto"
                  step={1}
                  marks
                  min={1}
                  max={5}
                />
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography variant="body2" color="text.secondary">Muy difícil</Typography>
                  <Typography variant="body2" color="text.secondary">Muy fácil</Typography>
                </Box>
              </Box>
            </Grid>
            
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">¿Recomendarías nuestra aplicación a amigos o familiares?</FormLabel>
                <RadioGroup
                  name="recommendation"
                  value={feedback.recommendation}
                  onChange={handleChange}
                  row
                >
                  <FormControlLabel value="1" control={<Radio />} label="Definitivamente no" />
                  <FormControlLabel value="2" control={<Radio />} label="Probablemente no" />
                  <FormControlLabel value="3" control={<Radio />} label="Tal vez" />
                  <FormControlLabel value="4" control={<Radio />} label="Probablemente sí" />
                  <FormControlLabel value="5" control={<Radio />} label="Definitivamente sí" />
                </RadioGroup>
              </FormControl>
            </Grid>
            
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="¿Qué es lo que más te gusta de nuestra plataforma?"
                name="likedFeatures"
                value={feedback.likedFeatures}
                onChange={handleChange}
                multiline
                rows={3}
                variant="outlined"
              />
            </Grid>
            
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="¿Qué podríamos mejorar?"
                name="improvements"
                value={feedback.improvements}
                onChange={handleChange}
                multiline
                rows={3}
                variant="outlined"
              />
            </Grid>
            
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  endIcon={<SendIcon />}
                >
                  Enviar Opinión
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Paper>
      
      <Snackbar 
        open={openSnackbar} 
        autoHideDuration={6000} 
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => setOpenSnackbar(false)} 
          severity="success"
          sx={{ width: '100%' }}
        >
          ¡Gracias por tus comentarios! Tu opinión nos ayuda a mejorar.
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AyudanosMejorar;