import dayjs from 'dayjs';

const formatDateForInput = (dateString) => {
    if (!dateString) return '';
    
    if (dateString.includes('/')) {
      const [day, month, year] = dateString.split('/');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    }
    
    // Fallback for other formats
    try {
      const date = new Date(dateString);
      if (!isNaN(date.getTime())) {
        return date.toISOString().split('T')[0];
      }
    } catch (e) {}
    
    return '';
  };


  function formatDateToDDMMYYYY(date, inputFormat = null) {
    if (!date) return '';
    
    try {
      if (inputFormat) {
        return dayjs(date, inputFormat).format('DD/MM/YYYY');
      }
      return dayjs(date).format('DD/MM/YYYY');
    } catch (error) {
      console.error('Error formatting date:', error);
      return '';
    }
  }

  export {formatDateForInput , formatDateToDDMMYYYY};