// src/components/AppointmentBooking.jsx
import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  Chip,
  Avatar,
  Grid,
  Divider,
  Snackbar,
  Alert
} from '@mui/material';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

// Mock API to get available time slots for a professional
const fetchAvailableSlots = (professionalId) => {
  return new Promise((resolve) => {
    // Simulate API delay
    setTimeout(() => {
      // Generate time slots for next 14 days
      const slots = [];
      const startDate = new Date();
      const endDate = new Date();
      endDate.setDate(endDate.getDate() + 14);
      
      // Random time slot generator
      for (let day = new Date(startDate); day <= endDate; day.setDate(day.getDate() + 1)) {
        // Skip weekends
        if (day.getDay() === 0 || day.getDay() === 6) continue;
        
        // 2-4 slots per day
        const slotsPerDay = Math.floor(Math.random() * 3) + 2;
        
        for (let i = 0; i < slotsPerDay; i++) {
          const hour = Math.floor(Math.random() * 8) + 9; // 9 AM to 5 PM
          const dayStr = day.toISOString().split('T')[0];
          
          slots.push({
            id: `slot-${dayStr}-${hour}`,
            title: 'Disponible',
            start: `${dayStr}T${hour.toString().padStart(2, '0')}:00:00`,
            end: `${dayStr}T${(hour+1).toString().padStart(2, '0')}:00:00`,
            color: '#4CAF93', // This matches your success color
            className: 'available-slot', // Add this class name
            extendedProps: {
              professionalId
            }
          });
        }
      }
      
      resolve(slots);
    }, 700);
  });
};

// Mock API for booking an appointment
const bookAppointment = (data) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      console.log('Appointment booked:', data);
      resolve({ success: true, id: Math.random().toString(36).substring(7) });
    }, 1000);
  });
};

const AppointmentBooking = ({ professional, open, onClose }) => {
  const [timeSlots, setTimeSlots] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [bookingDialog, setBookingDialog] = useState(false);
  const [bookingData, setBookingData] = useState({
    reason: '',
    notes: ''
  });
  const [submitting, setSubmitting] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  useEffect(() => {
    if (!professional || !open) return;
    
    setLoading(true);
    fetchAvailableSlots(professional.id)
      .then(slots => {
        setTimeSlots(slots);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching time slots:', error);
        setLoading(false);
        setSnackbar({
          open: true,
          message: 'Error al cargar horarios disponibles',
          severity: 'error'
        });
      });
  }, [professional, open]);

  const handleEventClick = (info) => {
    setSelectedSlot({
      id: info.event.id,
      start: info.event.start,
      end: info.event.end,
      title: info.event.title
    });
    setBookingDialog(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBookingData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleBookAppointment = async () => {
    if (!selectedSlot || !professional) return;
    
    setSubmitting(true);
    
    try {
      const appointmentData = {
        professionalId: professional.id,
        professionalName: professional.nombre,
        slotId: selectedSlot.id,
        startTime: selectedSlot.start.toISOString(),
        endTime: selectedSlot.end.toISOString(),
        reason: bookingData.reason,
        notes: bookingData.notes
      };
      
      const result = await bookAppointment(appointmentData);
      
      if (result.success) {
        setSnackbar({
          open: true,
          message: 'Cita reservada con éxito',
          severity: 'success'
        });
        
        setBookingDialog(false);
        setTimeout(() => {
          onClose({ success: true, appointment: appointmentData });
        }, 1500);
      }
    } catch (error) {
      console.error('Error booking appointment:', error);
      setSnackbar({
        open: true,
        message: 'Error al reservar la cita',
        severity: 'error'
      });
    } finally {
      setSubmitting(false);
    }
  };

  // Format date to human readable
  const formatDate = (date) => {
    return new Intl.DateTimeFormat('es-ES', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    }).format(date);
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      fullWidth
      maxWidth="md"
      PaperProps={{
        sx: {
          borderRadius: 2,
          boxShadow: '0 8px 32px rgba(0,0,0,0.1)'
        }
      }}
    >
      <DialogTitle>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar 
            src={professional?.foto} 
            sx={{ width: 50, height: 50, mr: 2 }}
          />
          <Box>
            <Typography variant="h6">
              Reservar hora con {professional?.nombre}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {professional?.especialidad}
            </Typography>
          </Box>
        </Box>
      </DialogTitle>
      
      <DialogContent>
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', py: 8 }}>
            <CircularProgress />
          </Box>
        ) : (
          <Box sx={{ mt: 2 }}>
            <Typography variant="body1" gutterBottom>
              Selecciona un horario disponible en el calendario:
            </Typography>
            
            <Paper elevation={0} 
  sx={{ 
    p: 2, 
    bgcolor: 'background.paper', 
    borderRadius: 2, 
    mt: 2,
    border: '1px solid rgba(187, 222, 251, 0.5)',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.05)',
    transition: 'all 0.3s ease',
    '&:hover': {
      boxShadow: '0 8px 30px rgba(0, 0, 0, 0.1)',
    }
  }}>
            <FullCalendar
  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
  initialView="timeGridWeek"
  headerToolbar={{
    left: 'prev,next today',
    center: 'title',
    right: 'dayGridMonth,timeGridWeek'
  }}
  events={timeSlots}
  eventClick={handleEventClick}
  height="auto"
  aspectRatio={1.8}
  businessHours={{
    daysOfWeek: [1, 2, 3, 4, 5],
    startTime: '09:00',
    endTime: '18:00'
  }}
  slotMinTime="08:00:00"
  slotMaxTime="19:00:00"
  locale="es"
  buttonText={{
    today: 'Hoy',
    month: 'Mes',
    week: 'Semana'
  }}
  nowIndicator={true}
  eventContent={(eventInfo) => (
    <Box sx={{ 
      p: 0.8,
      borderRadius: '4px',
      width: '100%',
      height: '100%',
      transition: 'all 0.2s',
      '&:hover': {
        opacity: 0.9,
      }
    }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 0.5 }}>
        <AccessTimeIcon fontSize="small" sx={{ mr: 0.5, fontSize: '1rem' }} />
        <Typography variant="caption" sx={{ fontWeight: 'bold', fontSize: '0.8rem' }}>
          {new Date(eventInfo.event.start).toLocaleTimeString('es-ES', {
            hour: '2-digit',
            minute: '2-digit'
          })}
        </Typography>
      </Box>
      <Typography 
        variant="caption" 
        sx={{ 
          fontSize: '0.75rem',
          display: 'block',
          textAlign: 'center',
          bgcolor: 'rgba(255,255,255,0.2)',
          borderRadius: '4px',
          padding: '2px 0',
        }}
      >
        Disponible
      </Typography>
    </Box>
  )}
/>
            </Paper>
          </Box>
        )}
      </DialogContent>
      
      <DialogActions>
        <Button onClick={onClose} color="inherit">Cancelar</Button>
      </DialogActions>
      
      {/* Booking confirmation dialog */}
      <Dialog open={bookingDialog} onClose={() => setBookingDialog(false)}>
        <DialogTitle>Confirmar reserva</DialogTitle>
        <DialogContent>
          {selectedSlot && (
            <Box sx={{ minWidth: 300, mt: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Paper variant="outlined" sx={{ p: 2, bgcolor: 'background.default' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                      <EventAvailableIcon color="primary" sx={{ mr: 1 }} />
                      <Typography variant="subtitle1">
                        {formatDate(selectedSlot.start)}
                      </Typography>
                    </Box>
                    
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                      <Avatar 
                        src={professional?.foto} 
                        sx={{ width: 24, height: 24, mr: 1 }}
                      />
                      <Typography variant="body2">
                        {professional?.nombre}
                      </Typography>
                    </Box>
                    
                    <Chip 
                      label={professional?.especialidad} 
                      size="small" 
                      color="primary" 
                      variant="outlined"
                    />
                  </Paper>
                </Grid>
                
                <Grid item xs={12}>
                  <Divider textAlign="left">
                    <Typography variant="caption" color="text.secondary">
                      DETALLES DE LA CONSULTA
                    </Typography>
                  </Divider>
                </Grid>
                
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    label="Motivo de consulta"
                    name="reason"
                    value={bookingData.reason}
                    onChange={handleInputChange}
                    variant="outlined"
                  />
                </Grid>
                
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Notas adicionales"
                    name="notes"
                    value={bookingData.notes}
                    onChange={handleInputChange}
                    variant="outlined"
                    multiline
                    rows={3}
                    placeholder="Cualquier información adicional que el profesional deba conocer"
                  />
                </Grid>
              </Grid>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setBookingDialog(false)} disabled={submitting}>
            Cancelar
          </Button>
          <Button 
  onClick={handleBookAppointment} 
  variant="contained" 
  sx={{
    bgcolor: '#5C6BC0', // Secondary main
    '&:hover': {
      bgcolor: '#3949AB', // Secondary dark
    },
    boxShadow: '0 2px 10px rgba(92, 107, 192, 0.3)', // Secondary with shadow
    textTransform: 'none',
    fontWeight: 600,
  }}
  disabled={submitting || !bookingData.reason}
  startIcon={submitting ? <CircularProgress size={20} color="inherit" /> : null}
>
  {submitting ? 'Procesando...' : 'Confirmar Reserva'}
</Button>
        </DialogActions>
      </Dialog>
      
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => setSnackbar(prev => ({ ...prev, open: false }))} 
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default AppointmentBooking;