import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#bbdefb',
      light: '#D6EAFD',
      dark: '#90CAF9',
      contrastText: '#2C3E50',
    },
    secondary: {
      main: '#5C6BC0',
      light: '#8E99F3',
      dark: '#3949AB',
      contrastText: '#FFFFFF',
    },
    background: {
      default: '#bbdefb',
      paper: '#FFFFFF',
    },
    text: {
      primary: '#2C3E50',
      secondary: '#455A64',
    },
    success: {
      main: '#4CAF93',
    },
    error: {
      main: '#EF5350',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontWeight: 600,
      fontSize: '2.5rem',
      lineHeight: 1.2,
      color: '#2C3E50',
    },
    h2: {
      fontWeight: 500,
      fontSize: '2rem',
      lineHeight: 1.3,
      color: '#2C3E50',
    },
    h6: {
      color: '#2C3E50',
      fontWeight: 600,
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.6,
      color: '#455A64',
    },
    button: {
      textTransform: 'none',
      fontWeight: 500,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          padding: '10px 20px',
        },
        contained: {
          boxShadow: '0 4px 8px rgba(44, 62, 80, 0.15)',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#FFFFFF',
          color: '#2C3E50',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 12,
          boxShadow: '0 4px 12px rgba(44, 62, 80, 0.08)',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: '0 2px 10px rgba(44, 62, 80, 0.1)',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: 'rgba(44, 62, 80, 0.1)',
        },
      },
    },
  },
  shape: {
    borderRadius: 10,
  },
});

export default theme;