import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    // Note: We won’t force "Content-Type: application/json" globally,
    //       because we may need multipart/form-data for file uploads.
    // You can remove or keep these default headers as needed.
    headers: {
        "Access-Control-Allow-Origin": "*",
        //"Content-Type": "application/json", // <-- Remove or comment out if it causes issues
    },
});


const saveOrUpdateUser = async ({ ...data }) => {
    try {
        const response = await api.post("/api/user/", { ...data });
        return response;
    } catch (error) {
        console.error("Failed to update user:", error.response || error.message);
        throw error;
    }
};


const getUser = async (email, token) => {
    const response = await api.get('/api/user/', {
        params: { email: email },
        headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
        }
    });
    return response;
};


const loadUserConversation = async (email) => {
    try {

        const response = await api.get(`/api/chat-bot/messages?email=${encodeURIComponent(email)}`);
        return response.data;

    } catch (error) {
        console.error("Error getting messages from api :", error.response || error.message);
        throw error;
    }
}

const sendFormDataForQueryV2 = async (formData) => {
    try {
        // Using axios directly:
        //  - We don't manually set "Content-Type": axios will add "multipart/form-data" with boundary.
        const response = await api.post("/api/v2/query_v2", formData, {
            headers: {
                // "Content-Type": "multipart/form-data"  <-- optional, axios will detect if data is FormData
            },
        });
        // The server returns JSON with { user_message_text, assistant_message_text, assistant_audio_url }
        return response.data;
    } catch (error) {
        console.error("Error in sendFormDataForQueryV2:", error.response || error.message);
        throw error;
    }
};


export { saveOrUpdateUser, getUser, sendFormDataForQueryV2, loadUserConversation }