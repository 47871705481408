import React, { useState, useRef, useEffect } from 'react';
import { Box, IconButton, Slider, Typography, Stack } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';

const CustomAudioPlayer = ({ audioUrl }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [volume, setVolume] = useState(0.8);
  const [isMuted, setIsMuted] = useState(false);
  const [isReady, setIsReady] = useState(false);
  
  const audioRef = useRef(null);

  // Format time in MM:SS format
  const formatTime = (seconds) => {
    if (isNaN(seconds)) return "00:00";
    const mins = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  useEffect(() => {
    const audio = audioRef.current;
    
    const handleLoadedMetadata = () => {
      setDuration(audio.duration);
      setIsReady(true);
    };
    
    const handleTimeUpdate = () => {
      setCurrentTime(audio.currentTime);
      setProgress((audio.currentTime / audio.duration) * 100);
    };
    
    const handleEnded = () => {
      setIsPlaying(false);
      setProgress(0);
      setCurrentTime(0);
      audio.currentTime = 0;
    };

    audio.addEventListener('loadedmetadata', handleLoadedMetadata);
    audio.addEventListener('timeupdate', handleTimeUpdate);
    audio.addEventListener('ended', handleEnded);
    
    return () => {
      audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
      audio.removeEventListener('timeupdate', handleTimeUpdate);
      audio.removeEventListener('ended', handleEnded);
    };
  }, []);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = isMuted ? 0 : volume;
    }
  }, [volume, isMuted]);

  const togglePlayPause = () => {
    const audio = audioRef.current;
    
    if (isPlaying) {
      audio.pause();
    } else {
      audio.play();
    }
    
    setIsPlaying(!isPlaying);
  };

  const handleProgressChange = (_, newValue) => {
    const audio = audioRef.current;
    const newTime = (newValue / 100) * duration;
    
    audio.currentTime = newTime;
    setProgress(newValue);
    setCurrentTime(newTime);
  };

  const handleVolumeChange = (_, newValue) => {
    setVolume(newValue);
    if (isMuted && newValue > 0) {
      setIsMuted(false);
    }
  };

  const toggleMute = () => {
    setIsMuted(!isMuted);
  };

  return (
    <Box
      sx={{
        bgcolor: 'background.paper',
        borderRadius: 2,
        p: 1.5,
        boxShadow: '0 2px 8px rgba(0,0,0,0.08)',
        width: '100%',
      }}
    >
      <audio ref={audioRef} src={audioUrl} preload="metadata" />
      
      <Stack spacing={1}>
        {/* Main controls */}
        <Stack direction="row" spacing={1.5} alignItems="center">
          <IconButton 
            onClick={togglePlayPause} 
            disabled={!isReady}
            sx={{ 
              color: 'secondary.main',
              bgcolor: 'primary.main', 
              '&:hover': { bgcolor: '#90caf9' },
              width: 40, 
              height: 40,
            }}
          >
            {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
          </IconButton>
          
          <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
            <Slider
              value={progress}
              onChange={handleProgressChange}
              sx={{ 
                color: 'primary.main',
                '& .MuiSlider-thumb': {
                  width: 12,
                  height: 12,
                  transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
                  '&:hover, &.Mui-focusVisible': {
                    boxShadow: '0px 0px 0px 8px rgba(33, 150, 243, 0.16)',
                  },
                  '&:before': {
                    boxShadow: '0px 0px 1px 0px rgba(0,0,0,0.2)',
                  },
                },
                '& .MuiSlider-rail': {
                  opacity: 0.28,
                },
              }}
            />
          </Box>
          
          <Box sx={{ minWidth: 65, textAlign: 'right' }}>
            <Typography variant="caption" color="text.secondary">
              {formatTime(currentTime)} / {formatTime(duration)}
            </Typography>
          </Box>
        </Stack>
        
        {/* Volume controls - optional, can be removed if too much */}
        <Stack direction="row" spacing={1} alignItems="center" sx={{ opacity: 0.8 }}>
          <IconButton onClick={toggleMute} size="small" sx={{ color: 'text.secondary' }}>
            {isMuted ? <VolumeOffIcon fontSize="small" /> : <VolumeUpIcon fontSize="small" />}
          </IconButton>
          
          <Slider
            value={isMuted ? 0 : volume}
            onChange={handleVolumeChange}
            min={0}
            max={1}
            step={0.01}
            aria-label="Volume"
            size="small"
            sx={{ 
              color: 'text.secondary',
              width: 80,
              '& .MuiSlider-thumb': {
                width: 10,
                height: 10,
              },
            }}
          />
        </Stack>
      </Stack>
    </Box>
  );
};

export default CustomAudioPlayer;