// src/components/ProfessionalsList.jsx
import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  Chip,
  Divider,
  CircularProgress,
  Snackbar,
  Alert,
  Avatar,
  Stack,
  Paper
} from '@mui/material';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import SchoolIcon from '@mui/icons-material/School';
import { Link } from 'react-router-dom';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import AppointmentBooking from './AppointmentBooking';

const fetchProfessionals = () => {
  return new Promise((resolve) => {
    // Simulate API delay
    setTimeout(() => {
      resolve([
        {
          id: 1,
          nombre: "Dra. María Fernández",
          foto: "https://randomuser.me/api/portraits/women/44.jpg",
          especialidad: "Psicología Clínica",
          universidad: "Universidad de Chile",
          resena: "Especialista en terapia cognitivo-conductual con 8 años de experiencia en tratamiento de trastornos de ansiedad, depresión y estrés postraumático.",
          proximaHora: "Mañana, 10:00 AM"
        },
        {
          id: 2,
          nombre: "Dr. Carlos Mendoza",
          foto: "https://randomuser.me/api/portraits/men/32.jpg",
          especialidad: "Psicoterapia",
          universidad: "Universidad Católica",
          resena: "Enfoque en manejo de estrés y ansiedad en adultos jóvenes. Especializado en terapias breves y soluciones prácticas para problemas cotidianos.",
          proximaHora: "Hoy, 15:30 PM"
        },
        {
          id: 3,
          nombre: "Dra. Ana Gutiérrez",
          foto: "https://randomuser.me/api/portraits/women/68.jpg",
          especialidad: "Neuropsicología",
          universidad: "Universidad Andrés Bello",
          resena: "Especializada en evaluación y rehabilitación neuropsicológica. Experiencia en diagnóstico y tratamiento de trastornos del neurodesarrollo en niños y adolescentes.",
          proximaHora: "Miércoles, 09:15 AM"
        },
        {
          id: 4,
          nombre: "Dr. Juan Pérez",
          foto: "https://randomuser.me/api/portraits/men/52.jpg",
          especialidad: "Psicología Infantil",
          universidad: "Universidad de Santiago",
          resena: "12 años de experiencia trabajando con niños y adolescentes. Especialista en problemas de conducta, TDAH y dificultades de aprendizaje.",
          proximaHora: "Jueves, 16:00 PM"
        },
        {
          id: 5,
          nombre: "Dra. Valentina Rojas",
          foto: "https://randomuser.me/api/portraits/women/23.jpg",
          especialidad: "Terapia de Parejas",
          universidad: "Universidad Diego Portales",
          resena: "Terapeuta de parejas y familias con enfoque sistémico. Ayuda a resolver conflictos, mejorar la comunicación y fortalecer vínculos.",
          proximaHora: "Viernes, 11:30 AM"
        },
        {
          id: 6,
          nombre: "Dr. Sebastián Lagos",
          foto: "https://randomuser.me/api/portraits/men/76.jpg",
          especialidad: "Psicología Laboral",
          universidad: "Universidad de Concepción",
          resena: "Especialista en psicología organizacional y coaching ejecutivo. Experiencia en manejo del estrés laboral y burnout profesional.",
          proximaHora: "Lunes, 14:00 PM"
        },
        {
          id: 7,
          nombre: "Dra. Camila Ortiz",
          foto: "https://randomuser.me/api/portraits/women/90.jpg",
          especialidad: "Psicología Gestalt",
          universidad: "Universidad Central",
          resena: "Enfoque humanista centrado en el presente. Especialista en crecimiento personal, autoconocimiento y desarrollo de potencialidades.",
          proximaHora: "Martes, 17:45 PM"
        },
        {
          id: 8,
          nombre: "Dr. Felipe Morales",
          foto: "https://randomuser.me/api/portraits/men/29.jpg",
          especialidad: "Psicooncología",
          universidad: "Universidad Austral de Chile",
          resena: "Especializado en el apoyo psicológico a pacientes con cáncer y sus familias. Manejo de crisis, duelo y adaptación a la enfermedad.",
          proximaHora: "Miércoles, 12:15 PM"
        }
      ]);
    }, 800);
  });
};

const ProfessionalsList = () => {
  const [professionals, setProfessionals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedProfessional, setSelectedProfessional] = useState(null);
  const [bookingDialogOpen, setBookingDialogOpen] = useState(false);
  const [appointmentSuccess, setAppointmentSuccess] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetchProfessionals()
      .then(data => {
        setProfessionals(data);
        setLoading(false);
      })
      .catch(error => {
        console.error("Error fetching professionals:", error);
        setError("No pudimos cargar la lista de profesionales. Intenta nuevamente.");
        setLoading(false);
      });
  }, []);

  const handleOpenBooking = (professional) => {
    setSelectedProfessional(professional);
    setBookingDialogOpen(true);
  };

  const handleCloseBooking = (result) => {
    setBookingDialogOpen(false);
    if (result && result.success) {
      setAppointmentSuccess(true);
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ textAlign: 'center', py: 4 }}>
        <Typography color="error">{error}</Typography>
        <Button variant="contained" sx={{ mt: 2 }} onClick={() => window.location.reload()}>
          Reintentar
        </Button>
      </Box>
    );
  }

  return (
    <Box>
<Paper 
  elevation={0} 
  sx={{ 
    p: 3, 
    mb: 4, 
    bgcolor: 'primary.light', 
    borderRadius: 2,
    position: 'relative',
    overflow: 'hidden'
  }}
>
  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
    <Box>
      <Typography variant="h4" fontWeight={600} sx={{ mb: 1 }}>
        Reservar Hora
      </Typography>
      
      <Typography variant="body1" sx={{ mb: 3 }}>
        Selecciona un profesional para ver su disponibilidad y agendar una consulta.
      </Typography>
    </Box>
    
    <Box 
      sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center',
        background: 'rgba(255, 255, 255, 0.15)',
        p: 2,
        borderRadius: 2,
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.05)',
        border: '1px solid rgba(255, 255, 255, 0.2)',
        backdropFilter: 'blur(5px)',
        maxWidth: 220
      }}
    >
      <Typography 
        variant="subtitle1" 
        fontWeight={600} 
        color="text.primary"
        sx={{ mb: 1, textAlign: 'center' }}
      >
        ¿No sabes qué profesional elegir?
      </Typography>
      
      <Button
        component={Link}
        to="/dashboard/matching-ia"
        variant="contained"
        color="secondary"
        size="large"
        sx={{
          py: 1,
          px: 3,
          fontWeight: 600,
          color: 'white',
          boxShadow: '0 6px 12px rgba(92, 107, 192, 0.3)',
          '&:hover': {
            transform: 'translateY(-2px)',
            boxShadow: '0 8px 16px rgba(92, 107, 192, 0.4)',
          },
          transition: 'all 0.2s ease',
          position: 'relative',
          overflow: 'hidden',
          '&::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'linear-gradient(120deg, transparent, rgba(255,255,255,0.2), transparent)',
            transform: 'translateX(-100%)',
          },
          '&:hover::after': {
            transform: 'translateX(100%)',
            transition: 'transform 0.6s ease',
          }
        }}
        startIcon={<AutoFixHighIcon />}
      >
        Lo hacemos por ti
      </Button>
      
      <Typography 
        variant="caption" 
        color="text.secondary" 
        sx={{ mt: 1, textAlign: 'center' }}
      >
        Nuestra IA te ayudará a encontrar el profesional ideal
      </Typography>
    </Box>
  </Box>
  
  {/* Decorative element */}
  <Box 
    sx={{ 
      position: 'absolute', 
      width: '250px', 
      height: '250px', 
      borderRadius: '50%', 
      background: 'linear-gradient(45deg, rgba(92, 107, 192, 0.15), rgba(187, 222, 251, 0.15))',
      top: '-125px',
      right: '-125px',
      zIndex: 0
    }} 
  />
</Paper>
      
      <Grid container spacing={3}>
        {professionals.map((professional) => (
          <Grid item xs={12} md={6} key={professional.id}>
            <Card sx={{ 
              height: '100%',
              transition: 'transform 0.2s, box-shadow 0.2s',
              borderRadius: 2,
              boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
              '&:hover': {
                transform: 'translateY(-4px)',
                boxShadow: '0 8px 20px rgba(0,0,0,0.1)'
              }
            }}>
              <CardContent sx={{ p: 3 }}>
                <Stack direction="row" spacing={2} alignItems="flex-start" sx={{ mb: 2 }}>
                  {/* Circular Avatar */}
                  <Avatar
                    src={professional.foto}
                    alt={professional.nombre}
                    sx={{ 
                      width: 70, 
                      height: 70, 
                      border: '3px solid #bbdefb',
                      boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
                    }}
                  />
                  
                  <Box>
                    <Typography variant="h6" gutterBottom fontWeight={600}>
                      {professional.nombre}
                    </Typography>
                    
                    <Chip 
                      label={professional.especialidad} 
                      color="primary"
                      size="small" 
                      sx={{ mb: 1, fontWeight: 500 }} 
                    />
                    
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <SchoolIcon fontSize="small" sx={{ color: 'text.secondary', mr: 1, fontSize: '0.9rem' }} />
                      <Typography variant="body2" color="text.secondary" fontSize="0.875rem">
                        {professional.universidad}
                      </Typography>
                    </Box>
                  </Box>
                </Stack>
                
                <Typography variant="body2" color="text.secondary" sx={{ mb: 2, lineHeight: 1.6 }}>
                  {professional.resena}
                </Typography>
                
                <Divider sx={{ my: 2 }} />
                
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', bgcolor: 'rgba(76, 175, 147, 0.1)', p: 1, borderRadius: 1 }}>
                    <EventAvailableIcon fontSize="small" sx={{ color: 'success.main', mr: 1 }} />
                    <Typography variant="body2" color="success.main" fontWeight={600}>
                      {professional.proximaHora}
                    </Typography>
                  </Box>
                  
                  <Button 
                    variant="contained" 
                    size='small'
                    onClick={() => handleOpenBooking(professional)}
                    
                    startIcon={<EventAvailableIcon fontSize="small" />}
                  >
                    Reservar
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      
      {/* Appointment Booking Dialog */}
      {selectedProfessional && (
        <AppointmentBooking
          professional={selectedProfessional}
          open={bookingDialogOpen}
          onClose={handleCloseBooking}
        />
      )}
      
      {/* Success Snackbar */}
      <Snackbar
        open={appointmentSuccess}
        autoHideDuration={6000}
        onClose={() => setAppointmentSuccess(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => setAppointmentSuccess(false)} 
          severity="success"
          sx={{ width: '100%', boxShadow: '0 4px 12px rgba(0,0,0,0.1)' }}
        >
          Tu cita ha sido reservada con éxito. Revisa tu correo electrónico para más detalles.
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ProfessionalsList;