import React, { useState } from 'react';
import { 
  Box, 
  Typography, 
  Paper, 
  Stepper, 
  Step, 
  StepLabel, 
  Button, 
  TextField, 
  Radio, 
  RadioGroup, 
  FormControlLabel, 
  FormControl, 
  FormLabel,
  CircularProgress,
  Chip,
  Avatar,
  Card,
  CardContent,
  Grid,
  Divider
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PsychologyIcon from '@mui/icons-material/Psychology';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';

const steps = ['Información básica', 'Tus necesidades', 'Resultados'];

const MatchingIA = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    age: '',
    gender: '',
    previousTherapy: '',
    mainIssue: '',
    preferredApproach: '',
    urgency: '',
    therapistGenderPreference: '',
    additionalInfo: ''
  });
  const [matchedProfessional, setMatchedProfessional] = useState(null);
  const navigate = useNavigate();

  const handleNext = () => {
    if (activeStep === steps.length - 2) {
      // Last step before results - simulate API call
      setLoading(true);
      setTimeout(() => {
        setMatchedProfessional({
          id: 5,
          nombre: "Dra. Valentina Rojas",
          foto: "https://randomuser.me/api/portraits/women/23.jpg",
          especialidad: "Terapia de Parejas",
          universidad: "Universidad Diego Portales",
          resena: "Terapeuta de parejas y familias con enfoque sistémico. Ayuda a resolver conflictos, mejorar la comunicación y fortalecer vínculos.",
          matchScore: 96,
          proximaHora: "Viernes, 11:30 AM",
          matchReasons: [
            "Experiencia en problemas de comunicación y relaciones",
            "Enfoque sistémico que coincide con tus preferencias",
            "Alta valoración por pacientes con situaciones similares",
            "Disponibilidad que se ajusta a tu nivel de urgencia"
          ]
        });
        setLoading(false);
        setActiveStep(prevActiveStep => prevActiveStep + 1);
      }, 2500);
    } else {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleBookAppointment = () => {
    navigate(`/book-appointment/${matchedProfessional.id}`);
  };

  return (
    <Box sx={{ py: 4, maxWidth: 800, mx: 'auto' }}>
      <Button 
        startIcon={<ArrowBackIcon />} 
        onClick={() => navigate(-1)}
        sx={{ mb: 2 }}
      >
        Volver
      </Button>
      
      <Paper 
        elevation={0}
        sx={{ 
          p: 4, 
          borderRadius: 3,
          background: 'linear-gradient(145deg, #bbdefb, #d6eafd)',
          boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
          mb: 4,
          position: 'relative',
          overflow: 'hidden'
        }}
      >
        <Box sx={{ position: 'relative', zIndex: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <AutoFixHighIcon sx={{ fontSize: 36, color: '#5C6BC0', mr: 2 }} />
            <Typography variant="h4" fontWeight={600} color="text.primary">
              Encuentra tu Match Profesional
            </Typography>
          </Box>
          
          <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
            Nuestra IA analizará tus necesidades para encontrar el profesional ideal para ti.
          </Typography>
        </Box>
        
        <Box sx={{ 
          position: 'absolute', 
          width: '300px', 
          height: '300px', 
          borderRadius: '50%', 
          background: 'radial-gradient(circle, rgba(142, 153, 243, 0.15) 0%, rgba(187, 222, 251, 0) 70%)',
          bottom: '-150px',
          right: '-150px',
          zIndex: 1
        }} />
      </Paper>
      
      <Paper elevation={0} sx={{ p: 4, borderRadius: 3 }}>
        <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        
        <Box>
          {activeStep === 0 && (
            <Box>
              <Typography variant="h6" sx={{ mb: 3 }}>Cuéntanos sobre ti</Typography>
              
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Edad"
                    name="age"
                    type="number"
                    value={formData.age}
                    onChange={handleChange}
                    variant="outlined"
                    required
                  />
                </Grid>
                
                <Grid item xs={12} sm={6}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Género</FormLabel>
                    <RadioGroup 
                      row 
                      name="gender" 
                      value={formData.gender} 
                      onChange={handleChange}
                    >
                      <FormControlLabel value="female" control={<Radio />} label="Femenino" />
                      <FormControlLabel value="male" control={<Radio />} label="Masculino" />
                      <FormControlLabel value="other" control={<Radio />} label="Otro" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                
                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">¿Has estado antes en terapia?</FormLabel>
                    <RadioGroup 
                      row 
                      name="previousTherapy" 
                      value={formData.previousTherapy} 
                      onChange={handleChange}
                    >
                      <FormControlLabel value="yes" control={<Radio />} label="Sí" />
                      <FormControlLabel value="no" control={<Radio />} label="No" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          )}
          
          {activeStep === 1 && (
            <Box>
              <Typography variant="h6" sx={{ mb: 3 }}>¿Qué estás buscando?</Typography>
              
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel>¿Cuál es el motivo principal de consulta?</FormLabel>
                    <RadioGroup 
                      name="mainIssue" 
                      value={formData.mainIssue} 
                      onChange={handleChange}
                    >
                      <FormControlLabel value="anxiety" control={<Radio />} label="Ansiedad" />
                      <FormControlLabel value="depression" control={<Radio />} label="Depresión" />
                      <FormControlLabel value="relationships" control={<Radio />} label="Problemas de relación" />
                      <FormControlLabel value="stress" control={<Radio />} label="Estrés" />
                      <FormControlLabel value="selfEsteem" control={<Radio />} label="Autoestima" />
                      <FormControlLabel value="other" control={<Radio />} label="Otro" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel>¿Prefieres algún enfoque terapéutico en particular?</FormLabel>
                    <RadioGroup 
                      name="preferredApproach" 
                      value={formData.preferredApproach} 
                      onChange={handleChange}
                    >
                      <FormControlLabel value="cognitive" control={<Radio />} label="Cognitivo-conductual" />
                      <FormControlLabel value="systemic" control={<Radio />} label="Sistémico" />
                      <FormControlLabel value="psychoanalytic" control={<Radio />} label="Psicoanalítico" />
                      <FormControlLabel value="humanistic" control={<Radio />} label="Humanista" />
                      <FormControlLabel value="no_preference" control={<Radio />} label="Sin preferencia" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <FormLabel>¿Cuál es tu nivel de urgencia?</FormLabel>
                    <RadioGroup 
                      name="urgency" 
                      value={formData.urgency} 
                      onChange={handleChange}
                    >
                      <FormControlLabel value="high" control={<Radio />} label="Alta (esta semana)" />
                      <FormControlLabel value="medium" control={<Radio />} label="Media (próximas 2 semanas)" />
                      <FormControlLabel value="low" control={<Radio />} label="Baja (próximo mes)" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <FormLabel>¿Prefieres un/a terapeuta de algún género en particular?</FormLabel>
                    <RadioGroup 
                      name="therapistGenderPreference" 
                      value={formData.therapistGenderPreference} 
                      onChange={handleChange}
                    >
                      <FormControlLabel value="female" control={<Radio />} label="Femenino" />
                      <FormControlLabel value="male" control={<Radio />} label="Masculino" />
                      <FormControlLabel value="no_preference" control={<Radio />} label="Sin preferencia" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Información adicional"
                    name="additionalInfo"
                    value={formData.additionalInfo}
                    onChange={handleChange}
                    variant="outlined"
                    multiline
                    rows={3}
                    placeholder="Cualquier otro aspecto que consideres relevante para encontrar a tu profesional ideal"
                  />
                </Grid>
              </Grid>
            </Box>
          )}
          
          {activeStep === 2 && (
            <Box>
              {loading ? (
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', py: 6 }}>
                  <CircularProgress size={60} sx={{ mb: 3 }} />
                  <Typography variant="h6">
                    Analizando tus necesidades...
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                    Estamos buscando el mejor profesional para ti
                  </Typography>
                </Box>
              ) : (
                <Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                    <PsychologyIcon sx={{ fontSize: 30, color: '#5C6BC0', mr: 2 }} />
                    <Typography variant="h5" fontWeight={600}>
                      Hemos encontrado tu match ideal
                    </Typography>
                  </Box>
                  
                  <Card sx={{ 
                    mb: 4, 
                    borderRadius: 3,
                    boxShadow: '0 8px 24px rgba(92, 107, 192, 0.15)',
                    position: 'relative',
                    overflow: 'hidden'
                  }}>
                    <Box 
                      sx={{ 
                        position: 'absolute', 
                        top: 15, 
                        right: 15, 
                        bgcolor: '#5C6BC0', 
                        color: 'white',
                        py: 0.5,
                        px: 1.5,
                        borderRadius: 5,
                        display: 'flex',
                        alignItems: 'center',
                        boxShadow: '0 2px 8px rgba(92, 107, 192, 0.3)',
                        zIndex: 2
                      }}
                    >
                      <Typography variant="subtitle2" fontWeight={600}>
                        Match {matchedProfessional.matchScore}%
                      </Typography>
                    </Box>
                    
                    <CardContent sx={{ p: 3 }}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                          <Avatar 
                            src={matchedProfessional.foto} 
                            sx={{ 
                              width: 120, 
                              height: 120, 
                              border: '3px solid #bbdefb',
                              boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                              mb: 2
                            }}
                          />
                          
                          <Typography variant="h6" align="center" gutterBottom>
                            {matchedProfessional.nombre}
                          </Typography>
                          
                          <Chip 
                            label={matchedProfessional.especialidad} 
                            color="secondary"
                            sx={{ mb: 1 }}
                          />
                          
                          <Typography variant="body2" color="text.secondary" align="center">
                            {matchedProfessional.universidad}
                          </Typography>
                          
                          <Box sx={{ 
                            display: 'flex', 
                            alignItems: 'center', 
                            mt: 2,
                            bgcolor: 'rgba(76, 175, 147, 0.1)', 
                            p: 1, 
                            borderRadius: 1 
                          }}>
                            <EventAvailableIcon fontSize="small" sx={{ color: 'success.main', mr: 1 }} />
                            <Typography variant="body2" color="success.main" fontWeight={600}>
                              {matchedProfessional.proximaHora}
                            </Typography>
                          </Box>
                        </Grid>
                        
                        <Grid item xs={12} md={8}>
                          <Typography variant="subtitle1" fontWeight={600} gutterBottom>
                            Reseña Profesional
                          </Typography>
                          
                          <Typography variant="body2" paragraph>
                            {matchedProfessional.resena}
                          </Typography>
                          
                          <Divider sx={{ my: 2 }} />
                          
                          <Typography variant="subtitle1" fontWeight={600} gutterBottom>
                            Por qué consideramos que es ideal para ti:
                          </Typography>
                          
                          <Box component="ul" sx={{ pl: 2 }}>
                            {matchedProfessional.matchReasons.map((reason, index) => (
                              <Typography component="li" variant="body2" key={index} sx={{ mb: 1 }}>
                                {reason}
                              </Typography>
                            ))}
                          </Box>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                  
                  <Box sx={{ textAlign: 'center' }}>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      onClick={handleBookAppointment}
                      startIcon={<EventAvailableIcon />}
                      sx={{
                        py: 1.5,
                        px: 4,
                        fontWeight: 600,
                        boxShadow: '0 6px 16px rgba(92, 107, 192, 0.3)',
                      }}
                    >
                      Reservar con este profesional
                    </Button>
                    <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                      O puedes <Button color="secondary" size="small" onClick={() => navigate('/')}>volver a la lista</Button> para explorar otras opciones
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          )}
          
          <Box sx={{ display: 'flex', justifyContent: 'space-between', pt: 3 }}>
            <Button
              disabled={activeStep === 0 || activeStep === steps.length - 1 || loading}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Atrás
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleNext}
              disabled={(activeStep === steps.length - 1) || loading}
            >
              {activeStep === steps.length - 2 ? 'Encontrar match' : 'Siguiente'}
            </Button>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default MatchingIA;