import React, { useState } from 'react';
import { 
  AppBar, 
  Toolbar, 
  Box, 
  Button, 
  Typography,
  Container,
  Avatar,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

const Header = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  
  // For demo purposes, we'll mock the Auth0 hook
  const {isAuthenticated , loginWithRedirect} = useAuth0()
  
  const [loginDialogOpen, setLoginDialogOpen] = useState(false);
  const [registerDialogOpen, setRegisterDialogOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  // Mock login function
  const handleLogin = () => {
    if (email && password) {
  //    setIsAuthenticated(true);
      setLoginDialogOpen(false);
      navigate('/dashboard');
    }
  };

  // Mock register function
  const handleRegister = () => {
    if (email && password) {
    //  setIsAuthenticated(true);
      setRegisterDialogOpen(false);
      navigate('/dashboard');
    }
  };

  return (
    <AppBar 
        position="static" 
        color="transparent" 
        elevation={0} 
        sx={{ bgcolor: 'default' }}>
      <Container maxWidth="lg">
        <Toolbar disableGutters>
          <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
          <Avatar
              alt="White-Mirror Logo"
              src="/icono_wm.png"
              sx={{ width: 50, height: 50, mr: 2 }}
              variant="rounded"
            />
           <Typography variant="h6" component="div" sx={{ display: { xs: 'none', md: 'block' } }}>
             White-Mirror
           </Typography>
          </Box>
          
          {isAuthenticated ? (
            <Button 
              color="primary" 
              variant="contained" 
              onClick={() => navigate('/dashboard')}
            >
              Mi Cuenta
            </Button>
          ) : (
            <Box>
              <Button 
                color="secondary" 
                variant="outlined" 
                onClick={loginWithRedirect}
                sx={{ mr: 2 }}
              >
                Registrarse
              </Button>
              
                <Button 
                color="primary" 
                variant="contained" 
                onClick={loginWithRedirect}
              >
                Iniciar Sesión
              </Button>
            </Box>
          )}
        </Toolbar>
      </Container>

      {/* Mock Login Dialog */}
      <Dialog open={loginDialogOpen} onClose={() => setLoginDialogOpen(false)}>
        <DialogTitle>Iniciar Sesión</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Correo electrónico"
            type="email"
            fullWidth
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{ mb: 2, mt: 1 }}
          />
          <TextField
            margin="dense"
            label="Contraseña"
            type="password"
            fullWidth
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setLoginDialogOpen(false)}>Cancelar</Button>
          <Button onClick={handleLogin} variant="contained" color="primary">
            Iniciar Sesión
          </Button>
        </DialogActions>
      </Dialog>

      {/* Mock Register Dialog */}
      <Dialog open={registerDialogOpen} onClose={() => setRegisterDialogOpen(false)}>
        <DialogTitle>Crear Cuenta</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Correo electrónico"
            type="email"
            fullWidth
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{ mb: 2, mt: 1 }}
          />
          <TextField
            margin="dense"
            label="Contraseña"
            type="password"
            fullWidth
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setRegisterDialogOpen(false)}>Cancelar</Button>
          <Button onClick={handleRegister} variant="contained" color="primary">
            Registrarse
          </Button>
        </DialogActions>
      </Dialog>
    </AppBar>
  );
};

export default Header;